import axios from 'axios'
import Loading from 'vue-loading-overlay'
import * as config from '@/config.json'
export default {
  name: 'LoginComp',
  components: {
    Loading
  },
  data() {
    return {
      isLoading: false,
      email: '',
      password: '',
      passwordVisible: true
    }
  },
  methods: {
    async resetPassword() {
      const data = {
        email: this.email
      }

      const url = `${config.default.urlBase}/users/reset-password`

      try {
        const response = await axios({
          url: url,
          method: 'post',
          data,
          headers: {
            authorization: config.default.autorizationRetailer
          }
        })

        if (response.status == 200) {
          await this.$alert(`Solicitação de alteração de senha enviada para o e-mail ${this.email}!`, 'Sucesso', 'success')
        }
      } catch (error) {
        console.log({
          error
        })

        this.isLoading = false

        if (!error.response) {
          await this.$alert('Não foi possível conectar-se ao servidor. Verifique a conexão e tente novamente!', 'Erro', 'error')
        }
      }
    },

    showPassword() {
      let pass = document.getElementById('password')

      if (pass.type == 'password') {
        pass.type = 'text'
      } else {
        pass.type = 'password'
      }

      this.passwordVisible = !this.passwordVisible
      this.focus('password')
    },

    focus(id) {
      document.getElementById(id).focus()
    },

    async login() {
      const data = {
        email: this.email,
        password: this.password
      }

      const url = `${config.default.urlBase}/users/login`

      try {
        const response = await axios({
          url,
          method: 'post',
          data
        })

        if (response.status == 200) {
          localStorage.setItem('token', response.data.token)

          sessionStorage.setItem('logado', true)
          sessionStorage.setItem('permissions', JSON.stringify(response.data.permissions))

          localStorage.setItem('idUsuarioLogado', response.data.user.id)
          localStorage.setItem('usuarioLogado', response.data.user.name)
          localStorage.setItem('typeUser', response.data.user.type)
          localStorage.setItem('divisionId', response.data.user.divisionId)
          localStorage.setItem('divisionName', response.data.user.divisionName)

          this.$router.push('home')
        }
      } catch (error) {
        console.log({
          error
        })

        this.isLoading = false
        this.focus('email')

        sessionStorage.setItem('logado', false)

        if (error.message == 'Network Error') {
          await this.$alert('Não foi possível conectar-se ao servidor. Verifique a conexão e tente novamente!', 'Erro', 'error')
        } else if (error.response.status == 401) {
          this.$alert(`${error.response.data.message}`, 'Aviso', 'warning')
        } else {
          await this.$alert('Usuário ou senha incorreto!', 'Erro', 'error')
        }
      }
    },

    clearEmail() {
      this.email = ''
      this.focus('email')
    },

    clearlocalStorage() {
      localStorage.removeItem('idUsuarioLogado')
      localStorage.removeItem('usuarioLogado')
      localStorage.removeItem('token')
      localStorage.removeItem('typeUser')
      localStorage.removeItem('divisionId')
      localStorage.removeItem('divisionName')

      sessionStorage.setItem('logado', false)
    }
  },

  async beforeMount() {
    this.isLoading = true
    await this.clearlocalStorage()
    this.isLoading = false
  }
}
