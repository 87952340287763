import * as config from '@/config.json'

import { parsePhoneNumberFromString, isValidPhoneNumber } from 'libphonenumber-js'
import { copyToClipboard } from '../../functions/functions'

import Loading from 'vue-loading-overlay'
import LoadingComp from '../../components/LoadingComp.vue'
import axios from 'axios'

export default {
  name: 'GatewayDocument',
  components: {
    Loading,
    LoadingComp
  },
  data() {
    return {
      isLoading: false,
      token: '',

      selectedOptionApp: null,
      optionsCompany: [],
      phone: '',
      phoneError: '',
      countryCode: '',
      countryName: '',
      optionsApps: [],
      activationCode: '',
      selectedCountry: null,
      selectedCountryCode: '',
      formattedPhone: ''
    }
  },

  methods: {
    clearCompanyFilter() {
      this.selectedOptionApp = null
    },

    clearPhoneFilter() {
      this.phone = ''
    },

    async getApps() {
      const url = `${config.default.urlBase}/apps/user`

      try {
        this.isLoading = true
        const response = await axios({
          url,
          method: 'get',
          headers: {
            authorization: `Bearer ${this.token}`
          }
        })

        this.optionsApps = []

        if (response.status == 200) {
          this.isLoading = false

          this.optionsApps = await Promise.all(
            response.data.map(item => {
              return {
                value: item.id,
                text: item.name
              }
            })
          )

          if (this.optionsApps.length == 1) {
            if (!this.selectedOptionApp) {
              this.selectedOptionApp = this.optionsApps[0].value
            }
          }
        }
      } catch (error) {
        console.log({
          error
        })

        this.optionsApps = []
        this.isLoading = false

        if (!error.response) {
          await this.$alert('Não foi possível conectar-se ao servidor. Verifique a conexão e tente novamente!', 'Erro', 'error')
        }

        if (error.response.status == 403) {
          await this.$alert('Sua sessão expirou. Faça o login novamente!', 'Aviso', 'warning')
          this.$router.push('login')
        } else if (error.response.status == 404) {
          this.getNotification('Aviso', `${error.response.data.message}`, 'warning')
        } else {
          this.$alert(`Ocorreu um erro ao buscar as empresas! ${error.response.data.message}`, 'Aviso', 'warning')
        }
      }
    },

    async onInputPhone(value, country) {
      this.countryCode = country.countryCode

      if (!this.phone) {
        this.formattedPhone = ''
        return
      }

      try {
        const phoneNumber = parsePhoneNumberFromString(this.phone, this.countryCode)

        if (phoneNumber && isValidPhoneNumber(this.phoneInput, this.countryCode)) {
          this.formattedPhone = phoneNumber.formatInternational()
        }

        if (this.formattedPhone) {
          this.phone = this.formattedPhone
        }
      } catch (error) {
        console.log({
          error
        })
      }
    },

    buildData(selectedOptionApp, phone) {
      const data = {
        appId: selectedOptionApp,
        customerPhone: phone
      }

      return data
    },

    async getValidateAppCode() {
      try {
        this.isLoading = true
        this.activationCode = ''

        const url = `${config.default.urlBaseCustomer}/customer/activate/v1`
        const data = this.buildData(this.selectedOptionApp, this.phone)

        const response = await axios({
          url,
          method: 'post',
          data,
          headers: {
            authorization: `Bearer ${this.token}`
          }
        })

        if (response.status == 200) {
          this.isLoading = false

          this.activationCode = response.data.activationCode
        }
      } catch (error) {
        console.log({
          error
        })

        this.isLoading = false

        if (error.response.status == 403) {
          await this.$alert('Sua sessão expirou. Faça o login novamente!', 'Aviso', 'warning')
          this.$router.push('login')
        } else if (error.response.status == 404) {
          this.getNotification('Aviso', `${error.response.data.message}`, 'warning')
        } else {
          this.$alert(`Ocorreu um erro ao buscar o código de ativação! ${error.response.data.message}`, 'Aviso', 'warning')
        }
      }
    },

    redirect(ref) {
      this.$router.push(`/${ref}`)
    },

    async getNotification(title, body, type) {
      this.$vToastify.setSettings({
        position: 'bottom-center'
      })
      await this.$vtNotify({
        title: title,
        body: body,
        type: type
      })
    },

    focus(id) {
      document.getElementById(id).focus()
    },

    async copyValidationCode() {
      await copyToClipboard(this.activationCode)

      this.getNotification('Sucesso', 'Código de ativação copiado.', 'success')
    }
  },

  async beforeMount() {
    this.isLoading = true
    this.token = localStorage.getItem('token')
    await this.getApps()
    this.isLoading = false
  }
}
