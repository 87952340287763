import * as config from '@/config.json'

import * as functions from '../../../functions/functions'
import axios from 'axios'
import PictureInput from 'vue-picture-input'
import Loading from 'vue-loading-overlay'
import PaginationNav from '../../../components/PaginationNav.vue'
import PaginationMobile from '../../../components/PaginationMobile.vue'
import moment from 'moment/moment'

export default {
  name: 'PushCampaign',
  components: {
    Loading,
    PictureInput,
    PaginationNav,
    PaginationMobile
  },

  data: function () {
    return {
      isLoading: false,
      token: null,
      id: null,
      titulo: null,
      descricao: null,
      tabIndex: [0],
      pushTabIndex: 0,
      qtdClientesCadastrados: 0,
      url: null,

      pagination: {},
      paginationRange: 11,

      clientes: [],
      clientesFields: [
        'id',
        'nome',
        'sobrenome',
        {
          key: 'email',
          label: 'E-Mail'
        },
        {
          key: 'numero',
          label: 'Telefone'
        },
        {
          key: 'selecionado',
          label: ''
        }
      ],
      customerDevicesFilter: 1,
      clientesFieldsSelected: [
        'id',
        'nome',
        'sobrenome',
        {
          key: 'email',
          label: 'E-Mail'
        },
        {
          key: 'numero',
          label: 'Telefone'
        },
        {
          key: 'selecionado',
          label: ''
        }
      ],

      appsIdCampaign: [],
      customersIdCampaign: [],
      typeCampaign: 0,
      titleCampaign: '',
      descriptionCampaign: '',
      redirectIdCampaign: '',
      imageCampaign: '',

      mountedCampaign: null,

      clientesEscolhidos: [],
      selecionarTodosOsClientes: false,

      tituloPush: null,
      descricaoPush: null,
      isJornalzinho: null,
      qtdPush: null,
      countEnvio: null,

      tipoid: null,
      redirectid: null,
      filter: null,
      filterOn: [],

      currentPage: 1,
      perPage: 5,
      totalRows: 1,
      currentPageSelecao: 1,
      perPageSelecao: 5,
      totalRowsSelecao: 1,
      selected: [],
      chamadaCampanha: '',

      filterCustomers: '',
      currentPageCustomers: 1,
      perPageCustomers: 5,

      currentPageSites: 1,
      perPageSites: 5,
      sites: [],
      fieldsSites: [
        {
          key: 'selected',
          label: 'Selecionado'
        },
        {
          key: 'id',
          label: 'ID'
        },
        {
          key: 'fantasy',
          label: 'Nome'
        }
      ],

      fieldsSitesMobile: [
        {
          key: 'selected',
          label: ''
        },
        {
          key: 'name',
          label: 'Nome'
        }
      ],

      companies: [],
      fieldsCompanies: [
        {
          key: 'selected',
          label: 'Selecionado'
        },
        {
          key: 'id',
          label: 'Id'
        },
        {
          key: 'name',
          label: 'Nome'
        }
      ],

      fieldsCompaniesMobile: [
        {
          key: 'selected',
          label: ''
        },
        {
          key: 'name',
          label: 'Nome'
        }
      ],

      selectMode: 'multi',
      selectModeMobile: 'multi',

      currentPageApps: 1,
      perPageApps: 7,
      selectedApps: [],
      customers: [],
      customersFields: [
        {
          key: 'id',
          label: 'ID'
        },
        {
          key: 'name',
          label: 'Nome'
        },
        {
          key: 'email',
          label: 'E-Mail'
        },
        {
          key: 'birthDate',
          label: 'Nascimento'
        },
        {
          key: 'phone',
          label: 'Telefone'
        },
        {
          key: 'selecionado',
          label: 'Selecionar'
        }
      ],
      customersFieldsSelected: [
        {
          key: 'id',
          label: 'ID'
        },
        {
          key: 'name',
          label: 'Nome'
        },
        {
          key: 'email',
          label: 'E-Mail'
        },
        {
          key: 'birthDate',
          label: 'Nascimento'
        },
        {
          key: 'phone',
          label: 'Telefone'
        },
        {
          key: 'selecionado',
          label: 'Desmarcar Seleção'
        }
      ],
      customersSelected: [],
      resumeCampaign: [],
      fieldsResumeCampaign: [
        {
          key: 'statusCode',
          label: ''
        },
        {
          key: 'status',
          label: 'Status'
        },
        {
          key: 'appId',
          label: 'Id Aplicativo'
        },
        {
          key: 'appName',
          label: 'Aplicativo'
        },
        {
          key: 'totalCustomers',
          label: 'Total de Clientes'
        },
        {
          key: 'totalCustomersDevices',
          label: 'Total de Dispositivos'
        }
      ],
      optionsSitesByUser: [],
      selectedOptionsSitesByUser: null,
      birthDateStart: null,
      birthDateEnd: null,
      sitesId: '',
      birthDayStart: '',
      birthMonthStart: '',
      birthDayEnd: '',
      birthMonthEnd: '',
      qtdCustomersRegistered: 0,
      refDiscountCoupon: false,
      allowedSendCampaign: false
    }
  },

  methods: {
    focus(id) {
      document.getElementById(id).focus()
    },

    selectAllRows() {
      this.$refs.selectableTableApp.selectAllRows()
    },

    selectAllRowsMobile() {
      this.$refs.selectableTableAppMobile.selectAllRows()
    },

    async clearSelected() {
      this.$refs.selectableTableApp.clearSelected()
    },

    async clearSelectedMobile() {
      this.$refs.selectableTableAppMobile.clearSelected()
    },

    selectAllRowsSites() {
      this.$refs.selectableTableSites.selectAllRows()
    },

    clearSelectedSites() {
      this.$refs.selectableTableSites.clearSelected()
    },

    selectAllRowsSitesMobile() {
      this.$refs.selectableTableSitesMobile.selectAllRows()
    },

    clearSelectedSitesMobile() {
      this.$refs.selectableTableSitesMobile.clearSelected()
    },

    async getApps() {
      this.isLoading = true

      const url = `${config.default.urlBase}/apps/user/v1`

      try {
        const response = await axios({
          url,
          method: 'get',
          headers: {
            authorization: `Bearer ${this.token}`
          }
        })

        if (response.status == 200) {
          this.isLoading = false

          this.companies = response.data
        }
      } catch (error) {
        console.log({
          error
        })

        this.isLoading = false

        if (!error.response) {
          await this.$alert('Não foi possível conectar-se ao servidor. Verifique a conexão e tente novamente!', 'Erro', 'error')
        }

        if (error.response.status == 403) {
          await this.$alert('Sua sessão expirou. Faça o login novamente!', 'Aviso', 'warning')
          this.$router.push('login')
        } else if (error.response.status == 404) {
          this.getNotification('Aviso', `${error.response.data.message}`, 'warning')
        } else {
          this.$alert(`Ocorreu um erro ao buscar os aplicativos! ${error.response.data.message}`, 'Aviso', 'warning')
        }
      }
    },

    navigate(page) {
      this.searchCustomers(page)
    },

    async buildCustomers(appsId, sitesId, birthDateStart, birthDateEnd, unsubscribed, customerDevicesFilter, name, page, pageSize) {
      const data = new Object()

      const appIdsArray = new Array()
      appIdsArray.push(appsId)

      const appIdsSelected = new Array(appIdsArray.join(','))

      data.appsId = appIdsSelected

      if (sitesId) {
        const sitesIdArray = new Array(sitesId.join(','))
        data.sitesId = sitesIdArray
      }

      if (birthDateStart != null && birthDateEnd != null) {
        this.birthDayStart = moment(birthDateStart, 'DD/MM').format('DD')
        this.birthMonthStart = moment(birthDateStart, 'DD/MM').format('MM')

        this.birthDayEnd = moment(birthDateEnd, 'DD/MM').format('DD')
        this.birthMonthEnd = moment(birthDateEnd, 'DD/MM').format('MM')

        data.birthDayStart = this.birthDayStart
        data.birthDayEnd = this.birthDayEnd
        data.birthMonthStart = this.birthMonthStart
        data.birthMonthEnd = this.birthMonthEnd
      }

      if (unsubscribed != null) {
        data.unsubscribed = unsubscribed
      }

      data.customerDevicesFilter = customerDevicesFilter

      data.name = name

      data.page = page
      data.pageSize = pageSize

      return data
    },

    async clearFilterSites() {
      this.selectedOptionsSitesByUser = null

      await this.searchCustomers(this.currentPage)
    },

    async searchCustomers(page) {
      const url = `${config.default.urlBase}/customers/search`
      const builtCustomers = await this.buildCustomers(
        this.appsIdCampaign,
        this.sitesId,
        this.birthDateStart,
        this.birthDateEnd,
        false,
        this.customerDevicesFilter,
        this.filterCustomers,
        page,
        this.perPageSelecao
      )

      try {
        const response = await axios({
          url,
          method: 'POST',
          data: builtCustomers,
          headers: {
            authorization: `Bearer ${this.token}`
          }
        })

        if (response.status == 200) {
          this.isLoading = false

          this.pagination = response.data
          this.pagination.rowsPerPage = this.perPageSelecao
          this.pagination.paginationRange = this.paginationRange

          this.customers = response.data.items

          this.qtdCustomersRegistered = response.data.records

          localStorage.removeItem('idCustomersCouponDiscount')

          if (!this.refDiscountCoupon) {
            if (this.pushTabIndex == 0 && this.customersSelected.length > 0) {
              this.allowedSendCampaign = true
            } else if (this.pushTabIndex == 1 && this.qtdCustomersRegistered > 0) {
              this.allowedSendCampaign = true
            } else {
              this.allowedSendCampaign = false
            }
          }
        }
      } catch (error) {
        console.log({
          error
        })

        this.isLoading = false

        this.customers = []
        this.pagination = []

        this.qtdCustomersRegistered = 0

        if (!error.response) {
          await this.$alert('Não foi possível conectar-se ao servidor. Verifique a conexão e tente novamente!', 'Erro', 'error')
        }

        if (error.response.status == 403) {
          await this.$alert('Sua sessão expirou. Faça o login novamente!', 'Aviso', 'warning')
          this.$router.push('login')
        } else if (error.response.status == 404) {
          this.getNotification('Aviso', `${error.response.data.message}`, 'warning')
        } else {
          await this.$alert(`Ocorreu um erro ao buscar os clientes! ${error.response.data.message}`, 'Aviso', 'warning')
        }
      }
    },

    async buildDataAppIds(appIds) {
      const data = {}

      data.appIds = new Array(appIds.join(','))

      return data
    },

    async getSitesByAppType() {
      this.isLoading = true

      const url = `${config.default.urlBase}/sites/app/v1`
      const data = await this.buildDataAppIds(this.appsIdCampaign)

      try {
        const response = await axios({
          url,
          data,
          method: 'post',
          headers: {
            authorization: `Bearer ${this.token}`
          }
        })

        if (response.status == 200) {
          this.isLoading = false

          this.sites = response.data
        }
      } catch (error) {
        console.log({
          error
        })

        this.isLoading = false

        if (!error.response) {
          await this.$alert('Não foi possível conectar-se ao servidor. Verifique a conexão e tente novamente!', 'Erro', 'error')
        }

        if (error.response.status == 403) {
          await this.$alert('Sua sessão expirou. Faça o login novamente!', 'Aviso', 'warning')
          this.$router.push('login')
        } else if (error.response.status == 404) {
          this.getNotification('Aviso', `${error.response.data.message}`, 'warning')
        } else {
          await this.$alert(`Ocorreu um erro ao buscar as empresas! ${error.response.data.message}`, 'Aviso', 'warning')
        }
      }
    },

    async onRowSelected(apps) {
      this.selectedApps = apps

      if (this.selectedApps.length > 0) {
        const arrayApps = this.selectedApps.map(app => app.id)
        this.appsIdCampaign = new Array(arrayApps.join(','))

        await this.getSitesByAppType()
        await this.searchCustomers(this.currentPageCustomers)
      } else {
        this.appsIdCampaign = []
        this.sites = []
        this.customers = []
        this.optionsSitesByUser = null
        this.qtdCustomersRegistered = 0
      }
    },

    async onRowSelectedSites(sites) {
      if (sites != '') {
        const arraySitesId = await Promise.all(
          sites.map(site => {
            return site.id
          })
        )

        this.sitesId = new Array(arraySitesId.join(','))
      } else {
        this.sitesId = null
      }

      if (this.selectedApps.length > 0) {
        await this.searchCustomers(this.currentPageCustomers)
      }
    },

    async readParams() {
      this.titleCampaign = this.$route.params.title
      this.descriptionCampaign = this.$route.params.description
      this.typeCampaign = this.$route.params.type ? this.$route.params.type : 0
      this.redirectId = this.$route.params.redirectId
      this.appsIdCampaign = this.$route.params.appsId
      this.sitesId = this.$route.params.sitesId
      this.customersIdCampaign = Array.isArray(this.$route.params.customersId)
        ? this.$route.params.customersId
        : this.$route.params.customersId
        ? new Array(this.$route.params.customersId)
        : new Array()
      this.birthDateStart = this.$route.params.birthDateStart
      this.birthDateEnd = this.$route.params.birthDateEnd
      this.refDiscountCoupon = this.$route.params.refDiscountCoupon
      this.allowedSendCampaign = this.$route.params.allowedSendCampaign
    },

    redirect() {
      if (this.typeCampaign == 1) {
        this.$router.push('/discount-coupon-manager')
      } else if (this.typeCampaign == 2) {
        this.$router.push('/promotions')
      } else {
        const ref = localStorage.getItem('redirectCampaign') ? localStorage.getItem('redirectCampaign') : 'home'
        localStorage.removeItem('redirectCampaign')
        this.$router.push(ref)
      }
    },
    goBack() {
      this.$router.go(-1)
    },

    onRemoved() {
      this.imageCampaign = ''
    },

    onChange(image) {
      if (image) {
        this.imageCampaign = image
      }
    },

    removeById(id) {
      const arr = this
      arr.customersSelected.forEach(function (el, index) {
        if (el.id === id) {
          arr.customersSelected.splice(index, 1)
        }
      })
    },

    clearCustomers() {
      this.customersSelected = []
      this.customersIdCampaign = []
    },

    verifyCustomersSelected(id) {
      const filteredCustomers = this.customersSelected.filter(customers => customers.id == id)
      return filteredCustomers.length > 0
    },

    selectCustomers(record) {
      const customer = this.customersSelected.find(customer => customer.id == record.id)

      if (!customer) {
        this.customersSelected.push({
          id: record.id,
          name: record.name,
          email: record.email,
          birthDate: record.birthDate,
          phone: record.phone
        })
      } else {
        this.removeById(record.id)
      }

      if (this.customersSelected.length > 0) {
        if (this.customersSelected.length > 0) {
          const arrayCustomers = this.customersSelected.map(customer => customer.id)
          this.customersIdCampaign = new Array(arrayCustomers.join(','))
        }
      }
    },

    async getNotification(title, body, type) {
      this.$vToastify.setSettings({
        position: 'bottom-center'
      })
      await this.$vtNotify({
        title: title,
        body: body,
        type: type,
        duration: 3000
      })
    },

    async mountCampaign(
      appsId,
      customersId,
      type,
      redirectId,
      title,
      description,
      image,
      birthDateStart,
      birthDateEnd,
      sitesId,
      unsubscribed,
      customerDevicesFilter
    ) {
      let campaign = {}

      ;(campaign.appsId = appsId),
        (campaign.customersId = customersId ? customersId : new Array()),
        (campaign.type = type),
        (campaign.redirectId = redirectId),
        (campaign.title = title),
        (campaign.description = description)

      if (image != '' && image != null) {
        campaign.image = image
      }

      if (birthDateStart != null && birthDateEnd != null) {
        campaign.birthDayStart = moment(birthDateStart, 'DD/MM').format('DD')
        campaign.birthMonthStart = moment(birthDateStart, 'DD/MM').format('MM')

        campaign.birthDayEnd = moment(birthDateEnd, 'DD/MM').format('DD')
        campaign.birthMonthEnd = moment(birthDateEnd, 'DD/MM').format('MM')
      }

      if (sitesId) {
        const sitesIdArray = new Array(sitesId.join(','))
        campaign.sitesId = sitesIdArray
      } else {
        campaign.sitesId = new Array()
      }

      if (unsubscribed != null) {
        campaign.unsubscribed = unsubscribed
      }

      campaign.customerDevicesFilter = customerDevicesFilter

      return (this.mountedCampaign = campaign)
    },

    verifyCampaign() {
      if (this.tituloPush == null) {
        this.$alert('Necessário preencher o título para continuar!', 'Aviso', 'warning')
      } else if (this.descricaoPush == null) {
        this.$alert('Necessário preencher a descrição para continuar!', 'Aviso', 'warning')
      } else {
        if (!this.prepararPush()) {
          this.$alert('Necessário preencher o filtro para continuar!', 'Aviso', 'warning')
        } else {
          this.sendCampaign()
        }
      }
    },

    async showResume(resume) {
      if (resume.length > 0) {
        this.resumeIcon = '@/assets/success.png'
        await this.$bvModal.show('resumeCampaign')
      }
    },

    async hideModal() {
      this.$refs['resumeCampaign'].hide()

      if (this.typeCampaign == 1)
        this.$router.push({
          path: '/discount-coupon-manager'
        })
      if (this.typeCampaign == 2)
        this.$router.push({
          path: '/promotions'
        })
      if (this.typeCampaign == 0)
        this.$router.push({
          path: '/push-campaign-list'
        })
    },

    async clearBirthDate() {
      this.birthDateStart = null
      this.birthDateEnd = null
      await this.searchCustomers(this.currentPage)
    },

    async clearFilterCustomer() {
      this.filterCustomers = ''
      await this.searchCustomers(this.currentPage)
      this.focus('filterInputSelecao')
    },

    async sendCampaign() {
      const url = `${config.default.urlBase}/campaigns`

      const image = functions.base64ToArrayBuffer(this.imageCampaign)
      const imageMb = parseFloat(image).toFixed(2)

      if (imageMb > 2) {
        await this.$alert(
          `Ocorreu um erro gerar o push! A imagem fornecida possui ${imageMb} Mb e excede o tamanho máximo permitido de 2 Mb`,
          'Aviso',
          'warning'
        )

        return
      }

      const data = await this.mountCampaign(
        this.appsIdCampaign,
        this.customersIdCampaign,
        this.typeCampaign,
        this.redirectIdCampaign,
        this.titleCampaign,
        this.descriptionCampaign,
        this.imageCampaign,
        this.birthDateStart,
        this.birthDateEnd,
        this.sitesId,
        false,
        this.customerDevicesFilter
      )
      try {
        this.isLoading = true
        const response = await axios({
          url,
          method: 'POST',
          data,
          headers: {
            authorization: `Bearer ${this.token}`
          }
        })

        if (response.status == 200) {
          this.isLoading = false

          this.resumeCampaign = response.data

          await this.showResume(this.resumeCampaign)
        }
      } catch (error) {
        this.isLoading = false

        console.log({
          error
        })

        if (!error.response) {
          await this.$alert('Não foi possível conectar-se ao servidor. Verifique a conexão e tente novamente!', 'Erro', 'error')
        }

        if (error.response.status == 403) {
          await this.$alert('Sua sessão expirou. Faça o login novamente!', 'Aviso', 'warning')
          this.$router.push('login')
        } else if (error.response.status == 404) {
          this.getNotification('Aviso', `${error.response.data.message}`, 'warning')
        } else {
          await this.$alert(`Ocorreu um erro gerar o push! ${error.response.data.message}`, 'Aviso', 'warning')
        }
      }
    }
  },

  updated() {
    if (!this.refDiscountCoupon) {
      if (this.pushTabIndex == 0 && this.customersSelected.length > 0) {
        this.allowedSendCampaign = true
      } else if (this.pushTabIndex == 1 && this.qtdCustomersRegistered > 0) {
        this.allowedSendCampaign = true
      } else {
        this.allowedSendCampaign = false
      }
    }
  },

  async beforeMount() {
    this.isLoading = true

    this.token = localStorage.getItem('token')

    await this.readParams()

    await this.getApps()
    this.isLoading = false
  }
}
