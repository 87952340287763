import Loading from 'vue-loading-overlay'
import axios from 'axios'
import PaginationNav from '../../../components/PaginationNav.vue'
import PaginationMobile from '../../../components/PaginationMobile.vue'
import moment from 'moment'
import LoadingComp from '../../../components/LoadingComp.vue'

import * as config from '@/config.json'
import * as helpers from '../../../helpers/object-helper'

export default {
  name: 'PromotionsList',
  components: {
    Loading,
    PaginationNav,
    PaginationMobile,
    LoadingComp
  },

  props: ['source'],

  data: () => {
    return {
      helperUrlImage: helpers.UrlImage.NO_IMAGE.url,
      pagination: {},
      paginationRange: 11,
      token: '',
      isLoading: false,
      perPage: 8,
      currentPage: 1,
      selectedOptionCompany: null,
      optionsCompany: [],
      promotions: [],

      fieldsPromotionsSpecial: [
        {
          key: 'id',
          label: 'ID'
        },
        {
          key: 'imageUrl',
          label: 'Imagem'
        },
        {
          key: 'description',
          label: 'Descrição'
        },
        {
          key: 'startDate',
          label: 'Início'
        },
        {
          key: 'endDate',
          label: 'Fim'
        },
        {
          key: 'consumedProductQuantity',
          label: 'Qtd Consumida'
        },
        {
          key: 'wonProductAmount',
          label: 'Vlr Recebido'
        },
        {
          key: 'wonProductQuantity',
          label: 'Qtd Recebida'
        },
        {
          key: 'wonProductDiscount',
          label: 'Desconto Recebido'
        },
        {
          key: 'showDetails',
          label: ''
        }
      ],

      fieldsPromotions: [
        {
          key: 'id',
          label: 'ID'
        },
        {
          key: 'imageUrl',
          label: 'Imagem'
        },
        {
          key: 'description',
          label: 'Descrição'
        },
        {
          key: 'startDate',
          label: 'Início'
        },
        {
          key: 'endDate',
          label: 'Fim'
        },
        {
          key: 'showDetails',
          label: ''
        }
      ],

      paramasInfo: {},
      pagePagination: null,
      descriptionPromotion: '',
      selectedRadioPromotion: 0,
      optionsRadioPromotion: [
        {
          value: 0,
          text: 'Todos'
        },
        {
          value: 1,
          text: 'Especial'
        },
        {
          value: 2,
          text: 'Por Período'
        }
      ],

      selectedDatePromotionOption: null,
      datePromotionOptions: [
        {
          value: null,
          text: 'Selecione o Tipo'
        },
        {
          value: 0,
          text: 'Início da Promoção'
        },
        {
          value: 1,
          text: 'Fim da Promoção'
        },
        {
          value: 2,
          text: 'Período da Promoção'
        }
      ],

      startDate: moment(new Date()).format('YYYY-MM-DD'),
      endDate: moment(new Date()).format('YYYY-MM-DD'),
      totalRecords: 0,
      isBusy: false
    }
  },

  methods: {
    async clearLocalStoragePage() {
      localStorage.removeItem('selectedOptionCompanyPromotion')
      localStorage.removeItem('descriptionPromotion')
      localStorage.removeItem('selectedRadioPromotion')
      localStorage.removeItem('selectedDatePromotionOption')
      localStorage.removeItem('startDatePromotion')
      localStorage.removeItem('endDatePromotion')
      localStorage.removeItem('currentPagePromotion')
    },

    async redirect(ref) {
      await this.clearLocalStoragePage()

      this.$router.push(ref)
    },

    async buildParamsInfo(
      companyId,
      id,
      description,
      detailedDescription,
      urlImage,
      isSpecial,
      isApllied,
      isEnded,
      isFemale,
      isMale,
      isMondayActive,
      mondayStartTime,
      mondayEndTime,
      isTuesdayActive,
      tuesdayStartTime,
      tuesdayEndTime,
      isWednesdayActive,
      wednesdayStartTime,
      wednesdayEndTime,
      isThursdayActive,
      thursdayStartTime,
      thursdayEndTime,
      isFridayActive,
      fridayStartTime,
      fridayEndTime,
      isSaturdayActive,
      saturdayStartTime,
      saturdayEndTime,
      isSundayActive,
      sundayStartTime,
      sundayEndTime,
      startDate,
      endDate
    ) {
      this.paramasInfo = {
        companyId,
        id,
        description,
        detailedDescription,
        urlImage,
        isSpecial,
        isApllied,
        isEnded,
        isFemale,
        isMale,
        isMondayActive,
        mondayStartTime,
        mondayEndTime,
        isTuesdayActive,
        tuesdayStartTime,
        tuesdayEndTime,
        isWednesdayActive,
        wednesdayStartTime,
        wednesdayEndTime,
        isThursdayActive,
        thursdayStartTime,
        thursdayEndTime,
        isFridayActive,
        fridayStartTime,
        fridayEndTime,
        isSaturdayActive,
        saturdayStartTime,
        saturdayEndTime,
        isSundayActive,
        sundayStartTime,
        sundayEndTime,
        startDate,
        endDate
      }
    },

    async showInformation(promotion) {
      await this.buildParamsInfo(
        this.selectedOptionCompany,
        promotion.id,
        promotion.description,
        promotion.detailedDescription,
        promotion.imageUrl,
        promotion.isSpecial,
        promotion.isApllied,
        promotion.isEnded,
        promotion.isFemale,
        promotion.isMale,
        promotion.isMondayActive,
        promotion.mondayStartTime,
        promotion.mondayEndTime,
        promotion.isTuesdayActive,
        promotion.tuesdayStartTime,
        promotion.tuesdayEndTime,
        promotion.isWednesdayActive,
        promotion.wednesdayStartTime,
        promotion.wednesdayEndTime,
        promotion.isThursdayActive,
        promotion.thursdayStartTime,
        promotion.thursdayEndTime,
        promotion.isFridayActive,
        promotion.fridayStartTime,
        promotion.fridayEndTime,
        promotion.isSaturdayActive,
        promotion.saturdayStartTime,
        promotion.saturdayEndTime,
        promotion.isSundayActive,
        promotion.sundayStartTime,
        promotion.sundayEndTime,
        promotion.startDate,
        promotion.endDate
      )

      this.$router.push({
        name: 'PromotionInfo',
        params: this.paramasInfo
      })
    },

    async cleanSearch() {
      this.descriptionPromotion = ''

      await this.getPromotions(this.currentPage)
    },

    async getCompanies() {
      const url = `${config.default.urlBase}/companies/user`

      try {
        const response = await axios({
          url,
          method: 'get',
          headers: {
            authorization: `Bearer ${this.token}`
          }
        })

        if (response.status == 200) {
          this.optionsCompany = await Promise.all(
            response.data.map(item => {
              return {
                value: item.id,
                text: item.name
              }
            })
          )

          if (this.optionsCompany.length == 1) {
            if (!this.selectedOptionCompany) {
              this.selectedOptionCompany = this.optionsCompany[0].value
            }
          }

          if (this.selectedOptionCompany) {
            if (!this.pagePagination) {
              await this.getPromotions(this.currentPage)
            } else {
              await this.navigate(this.pagePagination)
            }
          }
        }
      } catch (error) {
        console.log({
          error
        })

        this.optionsCompany = []
        this.isLoading = false

        if (!error.response) {
          await this.$alert('Não foi possível conectar-se ao servidor. Verifique a conexão e tente novamente!', 'Erro', 'error')
        }

        if (error.response.status == 403) {
          await this.$alert('Sua sessão expirou. Faça o login novamente!', 'Aviso', 'warning')
          this.$router.push('login')
        } else if (error.response.status == 404) {
          this.getNotification('Aviso', `${error.response.data.message}`, 'warning')
        } else {
          await this.$alert(`Ocorreu um erro ao buscar as empresas! ${error.response.data.message}`, 'Aviso', 'warning')
        }
      }
    },

    async buildParamsPromotions(companyId, description, isSpecial, optionDate, startDate, endDate, page) {
      if (this.selectedOptionCompany != null) {
        localStorage.setItem('selectedOptionCompanyPromotion', this.selectedOptionCompany)

        let params = new Object()

        if (companyId != null) {
          params.companyId = companyId
        } else {
          return null
        }

        params.description = description

        if (isSpecial == 1) {
          params.isSpecial = true
        } else if (isSpecial == 2) {
          params.isSpecial = false
        }

        if (startDate && endDate) {
          let initialDate = moment(startDate).format('YYYY-MM-DD 00:00:00')
          let finalDate = moment(endDate).format('YYYY-MM-DD 23:59:59')

          if (!initialDate || !finalDate) {
            return null
          }

          if (finalDate < initialDate) {
            this.getNotification('Aviso', 'A data final deve ser maior que a data inicial!', 'warning')

            return null
          } else {
            if (optionDate == 0) {
              params.startDate = initialDate
              params.endDate = finalDate
              params.dateType = 0
            } else if (optionDate == 1) {
              params.startDate = initialDate
              params.endDate = finalDate
              params.dateType = 1
            } else if (optionDate == 2) {
              params.startDate = initialDate
              params.endDate = finalDate
              params.dateType = 2
            }
          }
        }

        params.pageSize = this.perPage
        params.page = page

        return params
      } else {
        return null
      }
    },

    async getPromotions(page) {
      this.isBusy = true

      const url = `${config.default.urlBase}/promotions/search`
      const params = await this.buildParamsPromotions(
        this.selectedOptionCompany,
        this.descriptionPromotion,
        this.selectedRadioPromotion,
        this.selectedDatePromotionOption,
        this.startDate,
        this.endDate,
        page
      )

      localStorage.setItem('selectedOptionCompanyPromotion', this.selectedOptionCompany)
      localStorage.setItem('descriptionPromotion', this.descriptionPromotion)
      localStorage.setItem('selectedRadioPromotion', this.selectedRadioPromotion)
      localStorage.setItem('selectedDatePromotionOption', this.selectedDatePromotionOption)
      localStorage.setItem('startDatePromotion', this.startDate)
      localStorage.setItem('endDatePromotion', this.endDate)
      localStorage.setItem('currentPagePromotion', page)

      try {
        const response = await axios({
          url,
          method: 'GET',
          params,
          headers: {
            authorization: `Bearer ${this.token}`
          }
        })

        if (response.status == 200) {
          this.isBusy = false

          this.pagination = response.data
          this.pagination.rowsPerPage = this.perPage
          this.pagination.paginationRange = this.paginationRange

          this.promotions = response.data.items
          this.moreInfo = response.data.items
          this.totalRecords = response.data.records
        }
      } catch (error) {
        console.log({
          error
        })

        this.isBusy = false
        this.isLoading = false

        this.promotions = []
        this.pagination = []
        this.totalRecords = 0

        if (!error.response) {
          await this.$alert('Não foi possível conectar-se ao servidor. Verifique a conexão e tente novamente!', 'Erro', 'error')
        }

        if (error.response.status == 403) {
          await this.$alert('Sua sessão expirou. Faça o login novamente!', 'Aviso', 'warning')
          this.$router.push('login')
        } else if (error.response.status == 404) {
          this.getNotification('Aviso', `${error.response.data.message}`, 'warning')
        } else {
          await this.$alert(`Ocorreu um erro ao buscar as promoções! ${error.response.data.message}`, 'Aviso', 'warning')
        }
      }
    },

    async navigate(page) {
      this.getPromotions(page)
    },

    async getNotification(title, body, type) {
      this.$vToastify.setSettings({
        position: 'bottom-center'
      })
      await this.$vtNotify({
        title: title,
        body: body,
        type: type,
        duration: 3000
      })
    }
  },

  async beforeMount() {
    this.isLoading = true
    this.token = localStorage.getItem('token')

    this.pagePagination = parseInt(localStorage.getItem('currentPagePromotion'))

    if (localStorage.getItem('selectedOptionCompanyPromotion') == 'null' || localStorage.getItem('selectedOptionCompanyPromotion') == null) {
      this.selectedOptionCompany = null
    } else {
      this.selectedOptionCompany = parseInt(localStorage.getItem('selectedOptionCompanyPromotion'))
    }

    if (localStorage.getItem('selectedRadioPromotion') == 'null' || localStorage.getItem('selectedRadioPromotion') == null) {
      this.selectedRadioPromotion = null
    } else {
      this.selectedRadioPromotion = parseInt(localStorage.getItem('selectedRadioPromotion'))
    }

    if (localStorage.getItem('selectedDatePromotionOption') == 'null' || localStorage.getItem('selectedDatePromotionOption') == null) {
      this.selectedDatePromotionOption = null
    } else {
      this.selectedDatePromotionOption = parseInt(localStorage.getItem('selectedDatePromotionOption'))
    }

    this.descriptionPromotion = localStorage.getItem('descriptionPromotion') ? localStorage.getItem('descriptionPromotion') : ''
    this.startDate = localStorage.getItem('startDatePromotion') ? localStorage.getItem('startDatePromotion') : moment(new Date()).format('YYYY-MM-DD')
    this.endDate = localStorage.getItem('endDatePromotion') ? localStorage.getItem('endDatePromotion') : moment(new Date()).format('YYYY-MM-DD')

    await this.getCompanies()

    this.isLoading = false
  }
}
