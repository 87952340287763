import * as config from '@/config.json'

import Loading from 'vue-loading-overlay'
import axios from 'axios'
import PaginationNav from '../../../components/PaginationNav.vue'
import PaginationMobile from '../../../components/PaginationMobile.vue'
import moment from 'moment'
import LoadingComp from '../../../components/LoadingComp.vue'

export default {
  name: 'SalesList',
  components: {
    Loading,
    PaginationNav,
    PaginationMobile,
    LoadingComp
  },
  data: () => {
    return {
      pagination: {},
      paginationRange: 6,
      currentPage: 1,
      perPage: 9,
      customer: null,
      token: null,
      itemsDetails: [],
      fieldsDetails: [],
      items: [],
      fields: [
        {
          key: 'details',
          label: ''
        },
        {
          key: 'order.siteName',
          label: 'Loja'
        },
        {
          key: 'order.id',
          label: 'ID'
        },
        {
          key: 'order.partnerId',
          label: 'ID Parceiro'
        },
        {
          key: 'order.createdAt',
          label: 'Data'
        },
        {
          key: 'order.customerId',
          label: 'ID do Cliente'
        },
        {
          key: 'order.customerName',
          label: 'Nome do Cliente'
        },
        {
          key: 'order.customerDocument',
          label: 'CPF do Cliente'
        },
        {
          key: 'order.itemsQuantity',
          label: 'Qtde Itens'
        },
        {
          key: 'order.itemsTotalValue',
          label: 'Subtotal'
        },
        {
          key: 'order.discountsAmount',
          label: 'Desc. Promocional'
        },
        {
          key: 'order.couponValue',
          label: 'Cupom'
        },
        {
          key: 'order.totalValue',
          label: 'Total'
        },
        {
          key: 'status',
          label: 'Status'
        },
        {
          key: 'urlPdfFile',
          label: 'PDF'
        },
        {
          key: 'urlXmlFile',
          label: 'XML'
        },
        {
          key: 'info',
          label: ''
        }
      ],
      selectedOption: 2,
      options: [
        {
          value: 0,
          text: 'Sem Intervalo de Datas'
        },
        {
          value: 1,
          text: 'Ontem'
        },
        {
          value: 2,
          text: 'Hoje'
        },
        {
          value: 3,
          text: 'Últimos 7 dias'
        },
        {
          value: 4,
          text: 'Últimos 15 dias'
        },
        {
          value: 5,
          text: 'Últimos 30 dias'
        },
        {
          value: 6,
          text: 'Com Intervalo de Datas'
        }
      ],

      selectedFilterOption: null,
      filterOptions: [
        {
          value: null,
          text: 'Todas'
        },
        {
          value: 0,
          text: 'Transmitidas'
        },
        {
          value: 1,
          text: 'Gravadas'
        },
        {
          value: 2,
          text: 'Processadas'
        },
        {
          value: 3,
          text: 'Estornadas'
        },
        {
          value: 4,
          text: 'Com Erros'
        }
      ],
      isLoading: false,
      selectedOptionCompany: null,
      optionsCompany: [],
      selectedOptionSite: null,
      optionsSite: [],
      typeUser: null,
      itemsSanitized: [],
      qtdOrders: 0,
      totalAmount: 0,
      averageAmount: 0,
      discountsAmount: 0,
      couponValue: 0,
      itemsValue: 0,
      pagePagination: null,

      startDate: moment(new Date()).format('YYYY-MM-DD'),
      endDate: moment(new Date()).format('YYYY-MM-DD'),
      totalRecords: 0,

      companyIdDashboard: null,
      siteIdDashboard: null,
      selectedOptionsDateDashboard: null,
      startDateDashboard: null,
      endDateDashboard: null,
      soldItems: [],
      payments: [],
      fieldsSoldItems: [
        {
          key: 'sku',
          label: 'Barras'
        },
        {
          key: 'description',
          label: 'Descrição'
        },
        {
          key: 'quantity',
          label: 'Qtde'
        },
        {
          key: 'unitValue',
          label: 'Valor Unitário'
        },
        {
          key: 'subTotal',
          label: 'SubTotal'
        },
        {
          key: 'discount',
          label: 'Desc. Promocional'
        },
        {
          key: 'couponsDiscountAmount',
          label: 'Cupom'
        },
        {
          key: 'netValue',
          label: 'Valor Total'
        }
      ],
      selectedFilterItem: null,
      filterItem: [
        {
          value: null,
          text: '--- Selecine ---'
        },
        {
          value: 1,
          text: 'Barras'
        },
        {
          value: 2,
          text: 'Descrição'
        }
      ],
      filter: null,
      errorDescription: '',
      isBusy: false,
      isBusyDetails: false
    }
  },
  methods: {
    redirect(ref) {
      if (ref == 'Home') {
        this.$router.push(`/home`)
      }
    },

    handleClear() {
      if (!this.filter) {
        this.getOrders(this.currentPage)
      }
    },

    clearFilter() {
      this.filter = ''
      this.getOrders(this.currentPage)
    },

    async navigate(page) {
      this.setPaginationLocalStorage(page)
      await this.getOrders(page)
    },

    async getNotification(title, body, type) {
      this.$vToastify.setSettings({
        position: 'bottom-center'
      })
      await this.$vtNotify({
        title: title,
        body: body,
        type: type,
        duration: 3000
      })
    },

    async getCompanies() {
      const url = `${config.default.urlBase}/companies/user`

      try {
        const response = await axios({
          url,
          method: 'get',
          headers: {
            authorization: `Bearer ${this.token}`
          }
        })

        if (response.status == 200) {
          this.optionsCompany = await Promise.all(
            response.data.map(item => {
              return {
                value: item.id,
                text: `${item.id} ${item.name}`
              }
            })
          )

          if (this.optionsCompany.length == 1) {
            if (!this.selectedOptionCompany) {
              this.selectedOptionCompany = this.optionsCompany[0].value
            }

            this.getSitesByCompany(this.selectedOptionCompany)
          }
        }
      } catch (error) {
        console.log({
          error
        })

        this.optionsCompany = []
        this.isLoading = false

        if (!error.response) {
          await this.$alert('Não foi possível conectar-se ao servidor. Verifique a conexão e tente novamente!', 'Erro', 'error')
        }

        if (error.response.status == 403) {
          await this.$alert('Sua sessão expirou. Faça o login novamente!', 'Aviso', 'warning')
          this.$router.push('login')
        } else if (error.response.status == 404) {
          this.getNotification('Aviso', `${error.response.data.message}`, 'warning')
        } else {
          await this.$alert(`Ocorreu um erro ao buscar as empresas! ${error.response.data.message}`, 'Aviso', 'warning')
        }
      }
    },

    async getSitesByCompany(companyId) {
      const url = `${config.default.urlBase}/sites/company/${companyId}`

      try {
        const response = await axios({
          url,
          method: 'get',
          headers: {
            authorization: `Bearer ${this.token}`
          }
        })

        if (response.status == 200) {
          this.sites = response.data

          this.optionsSite = await Promise.all(
            response.data.map(item => {
              return {
                value: item.id,
                text: `${item.id} ${item.name}`
              }
            })
          )

          if (this.optionsSite.length == 1 && this.selectedOptionSite == null) {
            this.selectedOptionSite = this.optionsSite[0].value

            if (!this.pagePagination) {
              await this.getOrders(this.currentPage)
            } else {
              localStorage.removeItem('currentPageSales')
              await this.navigate(this.pagePagination)
            }
          }

          if (this.optionsSite.length > 1 && this.typeUser != 'site') {
            if (!this.pagePagination) {
              await this.getOrders(this.currentPage)
            } else {
              localStorage.removeItem('currentPageSales')
              await this.navigate(this.pagePagination)
            }
          }
        }
      } catch (error) {
        console.log({
          error
        })

        this.optionsSite = []
        this.isLoading = false

        if (!error.response) {
          await this.$alert('Não foi possível conectar-se ao servidor. Verifique a conexão e tente novamente!', 'Erro', 'error')
        }

        if (error.response.status == 403) {
          await this.$alert('Sua sessão expirou. Faça o login novamente!', 'Aviso', 'warning')
          this.$router.push('login')
        }
        if (error.response.status == 404) {
          this.getNotification('Aviso', `${error.response.data.message}`, 'warning')
        }
      }
    },

    async clearSiteFilter() {
      this.selectedOptionSite = null

      await this.getOrders(this.currentPage)
    },

    async getOrders(page) {
      this.isBusy = true

      const url = `${config.default.urlBase}/sites/orders/search/complete`

      const params = this.buildParams(
        this.selectedOptionCompany,
        this.selectedOptionSite,
        this.selectedOption,
        this.selectedFilterOption,
        this.selectedFilterItem,
        page
      )

      this.setLocalStorage()
      this.setPaginationLocalStorage(page)

      if (params != null) {
        try {
          const response = await axios({
            url,
            method: 'get',
            params,
            headers: {
              authorization: `Bearer ${this.token}`
            }
          })

          if (response.status == 200) {
            this.isBusy = false

            this.pagination = response.data
            this.pagination.rowsPerPage = this.perPage
            this.pagination.paginationRange = this.paginationRange

            this.items = response.data.items

            this.qtdOrders = response.data.records
            this.totalAmount = response.data.totalAmount
            this.averageAmount = response.data.averageAmount
            this.discountsAmount = response.data.discountsAmount
            this.couponValue = response.data.couponValue
            this.itemsValue = response.data.itemsValue
            this.totalRecords = response.data.records
          }
        } catch (error) {
          console.log({
            error
          })

          this.isBusy = false

          this.items = []
          this.itemsSanitized = []
          this.pagination = []

          this.qtdOrders = 0
          this.totalAmount = 0
          this.averageAmount = 0
          this.totalRecords = 0
          this.discountsAmount = 0
          this.couponValue = 0
          this.itemsValue = 0

          if (!error.response) {
            await this.$alert('Não foi possível conectar-se ao servidor. Verifique a conexão e tente novamente!', 'Erro', 'error')
          }

          if (error.response.status == 403) {
            await this.$alert('Sua sessão expirou. Faça o login novamente!', 'Aviso', 'warning')
            this.$router.push('login')
          } else if (error.response.status == 404) {
            this.getNotification('Aviso', `${error.response.data.message}`, 'warning')
          } else {
            await this.$alert(`Ocorreu um erro ao buscar as vendas! ${error.response.data.message}`, 'Aviso', 'warning')
          }
        }
      }

      this.isBusy = false
    },

    readyParamsOrder() {
      this.companyIdDashboard = this.$route.params.companyId
      this.siteIdDashboard = this.$route.params.siteId
      this.selectedOptionsDateDashboard = this.$route.params.selectedOption
      this.startDateDashboard = this.$route.params.startDate
      this.endDateDashboard = this.$route.params.endDate
    },

    focus(id) {
      if (id === 'filterInput') {
        this.filter = ''
      }
      if (document.getElementById(id)) {
        document.getElementById(id).focus()
      }
    },

    async getOrderDetails(orderId) {
      this.isBusyDetails = true

      const url = `${config.default.urlBase}/sites/orders/${orderId}/details`

      try {
        const response = await axios({
          url,
          method: 'get',
          headers: {
            authorization: `Bearer ${this.token}`
          }
        })

        if (response.status == 200) {
          this.isBusyDetails = false
          this.itemsDetails = response.data

          this.soldItems = response.data.items
          this.payments = response.data.payments[0]
        }
      } catch (error) {
        console.log({
          error
        })

        this.isBusyDetails = false
        this.isLoading = false

        this.itemsDetails = []
        this.soldItems = []

        if (!error.response) {
          await this.$alert('Não foi possível conectar-se ao servidor. Verifique a conexão e tente novamente!', 'Erro', 'error')
        }

        if (error.response.status == 403) {
          await this.$alert('Sua sessão expirou. Faça o login novamente!', 'Aviso', 'warning')
          this.$router.push('login')
        } else if (error.response.status == 404) {
          this.getNotification('Aviso', `${error.response.data.message}`, 'warning')
        } else {
          await this.$alert(`Ocorreu um erro ao buscar as vendas! ${error.response.data.message}`, 'Aviso', 'warning')
        }
      }
    },

    setOrderLocalStorage(order) {
      localStorage.setItem('orderId', order.order.id)
    },

    setLocalStorage() {
      localStorage.setItem('companyIdOrder', this.selectedOptionCompany)
      localStorage.setItem('siteIdOrder', this.selectedOptionSite)
      localStorage.setItem('filterOption', this.selectedOption)
      localStorage.setItem('startDateOrder', this.startDate)
      localStorage.setItem('endDateOrder', this.endDate)
      localStorage.setItem('selectedFilterOptionSales', this.selectedFilterOption)
      localStorage.setItem('selectedFilterItem', this.selectedFilterItem)
      localStorage.setItem('filter', this.filter)
    },

    setPaginationLocalStorage(page) {
      localStorage.setItem('currentPageSales', page)
    },

    removeLocalStorage() {
      localStorage.removeItem('orderId')
      localStorage.removeItem('companyIdOrder')
      localStorage.removeItem('siteIdOrder')
      localStorage.removeItem('filterOption')
      localStorage.removeItem('startDateOrder')
      localStorage.removeItem('endDateOrder')
      localStorage.removeItem('currentPageSales')
      localStorage.removeItem('selectedFilterOptionSales')
      localStorage.removeItem('selectedFilterItem')
      localStorage.removeItem('filter')
    },

    async handleButtonClick(row) {
      await row.toggleDetails()

      this.setOrderLocalStorage(row.item)
      this.setLocalStorage()
    },

    async showDetails(order) {
      this.setOrderLocalStorage(order)
      this.setLocalStorage()

      this.$router.push('order-details')
    },

    buildParams(companyId, siteId, selectedOption, filter, selectedFilter, page) {
      if (this.selectedOptionCompany != null || this.selectedOptionSite != null) {
        let params = new Object()

        if (companyId != null && companyId != 'null') {
          params.companyId = companyId
        }

        if (siteId != null && siteId != 'null') {
          params.siteId = siteId
        }

        if (selectedOption == 1) {
          params.startDate = moment().subtract(1, 'days').format('YYYY-MM-DD 00:00:00')
          params.endDate = moment().subtract(1, 'days').format('YYYY-MM-DD 23:59:59')
        }

        if (selectedOption == 2) {
          params.startDate = moment().format('YYYY-MM-DD 00:00:00')
          params.endDate = moment().format('YYYY-MM-DD 23:59:59')
        }

        if (selectedOption == 3) {
          params.startDate = moment().subtract(6, 'days').format('YYYY-MM-DD 00:00:00')
          params.endDate = moment().format('YYYY-MM-DD 23:59:59')
        }

        if (selectedOption == 4) {
          params.startDate = moment().subtract(14, 'days').format('YYYY-MM-DD 00:00:00')
          params.endDate = moment().format('YYYY-MM-DD 23:59:59')
        }

        if (selectedOption == 5) {
          params.startDate = moment().subtract(29, 'days').format('YYYY-MM-DD 00:00:00')
          params.endDate = moment().format('YYYY-MM-DD 23:59:59')
        }

        if (selectedOption == 6) {
          if (this.startDate != null && this.endDate != null) {
            if (this.endDate < this.startDate) {
              this.getNotification('Aviso', 'A data final deve ser maior que a data inicial!', 'warning')

              this.isLoading = false

              return null
            } else {
              params.startDate = moment(this.startDate).format('YYYY-MM-DD 00:00:00')
              params.endDate = moment(this.endDate).format('YYYY-MM-DD 23:59:59')
            }
          }
        }

        if (filter == 0) {
          params.status = 'Transmitida'
        }

        if (filter == 1) {
          params.status = 'Gravada'
        }

        if (filter == 2) {
          params.status = 'Processada'
        }

        if (filter == 3) {
          params.isRefunded = true
        }

        if (filter == 4) {
          params.status = 'Erro'
        }

        if (selectedFilter == 1) {
          params.productSku = this.filter
        }

        if (selectedFilter == 2) {
          params.productDescription = this.filter
        }

        params.pageSize = this.perPage
        params.page = page

        return params
      } else {
        return null
      }
    },

    async openPdf(url, error) {
      if (url != null && url != '' && url != 'undefined') {
        window.open(url, '_blank')
      } else if (!error) {
        await this.$alert('Dado não disponível!', 'Aviso', 'warning')
      } else {
        await this.$alert('Dado não disponível devido um erro ocorrido na venda. Clique no icone do Status para mais detalhes!', 'Aviso', 'warning')
      }
    },

    async showOrderErrorDetails(order) {
      this.errorDescription = order.item.orderStatuses[0].message
      await this.$bvModal.show('orderErrorDetail')
    },

    async getLocalStorage() {
      this.pagePagination = parseInt(localStorage.getItem('currentPageSales'))
      this.selectedOption = localStorage.getItem('filterOption') ? localStorage.getItem('filterOption') : 2
      this.selectedOptionSite =
        localStorage.getItem('siteIdOrder') !== null && localStorage.getItem('siteIdOrder') !== 'null'
          ? parseInt(localStorage.getItem('siteIdOrder'))
          : null
      this.selectedFilterOption =
        localStorage.getItem('selectedFilterOptionSales') !== null && localStorage.getItem('selectedFilterOptionSales') !== 'null'
          ? parseInt(localStorage.getItem('selectedFilterOptionSales'))
          : null
      this.selectedOptionCompany =
        localStorage.getItem('companyIdOrder') !== null && localStorage.getItem('companyIdOrder') !== 'null'
          ? parseInt(localStorage.getItem('companyIdOrder'))
          : null
      this.startDate = localStorage.getItem('startDateOrder') ? localStorage.getItem('startDateOrder') : moment(new Date()).format('YYYY-MM-DD')
      this.endDate = localStorage.getItem('endDateOrder') ? localStorage.getItem('endDateOrder') : moment(new Date()).format('YYYY-MM-DD')
      this.selectedFilterItem =
        localStorage.getItem('selectedFilterItem') !== null && localStorage.getItem('selectedFilterItem') !== 'null'
          ? localStorage.getItem('selectedFilterItem')
          : null
      this.filter = localStorage.getItem('filter') !== null && localStorage.getItem('filter') !== 'null' ? localStorage.getItem('filter') : null
    }
  },

  async beforeMount() {
    this.isLoading = true
    this.token = localStorage.getItem('token')
    this.typeUser = localStorage.getItem('typeUser')

    await this.readyParamsOrder()

    await this.getLocalStorage()

    await this.getCompanies()

    if (this.companyIdDashboard && this.siteIdDashboard && this.selectedOptionsDateDashboard && this.startDateDashboard && this.endDateDashboard) {
      this.selectedOptionCompany = this.companyIdDashboard

      this.selectedOption = this.selectedOptionsDateDashboard
      this.startDate = this.startDateDashboard
      this.endDate = this.endDateDashboard
      this.selectedOptionSite = this.siteIdDashboard
    }

    if (this.selectedOptionCompany != null && this.selectedOptionCompany != '') {
      await this.getSitesByCompany(this.selectedOptionCompany)
    }

    await this.getOrders(this.pagePagination ? this.pagePagination : this.currentPage)

    this.isLoading = false
  },

  async unmounted() {
    this.removeLocalStorage()
  }
}
