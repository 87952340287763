import Vue from 'vue'
import VueRouter from 'vue-router'
import HomeView from '../views/Home/HomeView.vue'
import Login from '../views/Login/LoginView.vue'
import DefaultContainer from '../views/DefaultContainer/DefaultContainer.vue'
import ProductsList from '../views/Products/ProductsList.vue'
import UsersList from '../views/Users/UsersList/UsersListView.vue'
import UsersManager from '../views/Users/UsersManager/UsersManagerView.vue'
import ResetPassword from '../views/ResetPassword/ResetPassword.vue'
import Message from '../views/ResetPassword/Message/MessageResetPassword.vue'
import BannersList from '../views/Banners/Totem/BannersList/BannersList.vue'
import BannersManager from '../views/Banners/Totem/BannersManager/BannersManager.vue'
import Setup from '../views/Setup/SetupView.vue'
import BannerAppList from '../views/Banners/App/BannersList/BannersList.vue'
import BannerAppManager from '../views/Banners/App/BannersManager/BannersManager.vue'
import CustomerList from '../views/Customers/CustomersList.vue'
import SalesList from '../views/Sales/Orders/SalesList.vue'
import OrderDetails from '../views/Sales/Details/OrderDetails.vue'
import Monitoring from '../views/Monitoring/MonitoringView.vue'
import Tokens from '../views/Tokens/TokensApp.vue'
import Bot from '../views/Bot/Create/BotView.vue'
import BotBound from '../views/Bot/Bound/BotBoundPOS.vue'
import BotSpeechs from '../views/Bot/FindSpeechs/FindSpeechs.vue'
import BotBounded from '../views/Bot/SearchSpeechs/SearchSpeechs.vue'
import DiscountCouponList from '../views/DiscountCoupon/DiscountCouponList/DiscountCouponList.vue'
import DiscountCouponManager from '../views/DiscountCoupon/DiscountCouponManager/DiscountCouponManager.vue'
import PushCampaignList from '../views/PushCampaign/PushCampaignList/PushCampaignList.vue'
import PushCampaignManager from '../views/PushCampaign/PushCampaignManager/PushCampaignManager.vue'
import DetailsCouponDiscount from '../views/DiscountCoupon/DiscountCouponList/DetailsDiscountCoupon/DetailsDiscountCoupon.vue'
import PromotionsList from '../views/Promotions/PromotionsList/PromotionsList.vue'
import PromotionInfo from '../views/Promotions/PromotionInfo/PromotionInfo.vue'
import GatewayDocument from '../views/GatewayDocument/GatewayDocumentView.vue'
import ManagerUserPermissions from '../views/Users/UserPermissions/UserPermissionsView.vue'
import OrderItemsList from '../views/Sales/Order Items/OrderItems.vue'
import ProductsSite from '../views/ProductsSite/ProductsSite.vue'
import DeviceHistory from '../views/Control/DeviceHistory/DeviceHistory.vue'
import ApplicationsList from '../views/Applications/ApplicationsList.vue'
import StockBacklogs from '../views/Home/StockBacklogs/StockBacklogs.vue'
import EventsHistory from '../views/Control/EventsHistory/EventsHistory.vue'
import CustomersExceptionsList from '../views/CustomersExceptionsList/CustomersExceptionsList.vue'
import StockAdjustmentDocuments from '../views/Stock-Adjustment/DocumentsAndItems/DocumentsAndItems.vue'
import DetailsItemsDocuments from '../views/Stock-Adjustment/DetailsItemsDocuments/DetailsItemsDocuments.vue'
import StockAdjustmentItemsDocuments from '../views/Stock-Adjustment/StockAdjustmentItemsDocuments/StockAdjustmentItemsDocuments.vue'
import CustomersRecognitionGateway from '../views/GatewayRecognition/GatewayRecognition.vue'
import ValidateAppCode from '../views/ValidateAppCode/ValidateAppCode.vue'
import OrderPayments from '../views/Sales/Order Payments/OrderPayments.vue'
import PartnersPendingStatus from '../views/Home/PartnersPendingStatus/PartnersPendingStatus.vue'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    redirect: '/home',
    name: 'Início',
    component: DefaultContainer,
    children: [
      {
        path: 'home',
        name: 'HomeView',
        component: HomeView,
        meta: {
          requiresAuth: true
        }
      },
      {
        path: 'products-list',
        name: 'ProdutosList',
        component: ProductsList
      },
      {
        path: 'users-list',
        name: 'UsersList',
        component: UsersList
      },
      {
        path: 'users-manager',
        name: 'UsersManager',
        component: UsersManager
      },
      {
        path: 'banners-list',
        name: 'BannersList',
        component: BannersList
      },
      {
        path: 'banners-manager',
        name: 'BannersManager',
        component: BannersManager
      },
      {
        path: 'setup',
        name: 'Setup',
        component: Setup
      },
      {
        path: 'banners-app-list',
        name: 'BannerAppList',
        component: BannerAppList
      },
      {
        path: 'banners-app-manager',
        name: 'BannerAppManager',
        component: BannerAppManager
      },
      {
        path: 'customer-list',
        name: 'CustomerList',
        component: CustomerList
      },
      {
        path: 'orders-list',
        name: 'SalesList',
        component: SalesList
      },
      {
        path: 'order-items-list',
        name: 'OrderItemsList',
        component: OrderItemsList
      },
      {
        path: '/order-details',
        name: 'OrderDetails',
        component: OrderDetails
      },
      {
        path: '/monitoring-pos',
        name: 'Monitoring',
        component: Monitoring
      },
      {
        path: '/bot',
        name: 'Bot',
        component: Bot
      },
      {
        path: '/bot-bound',
        name: 'BotBound',
        component: BotBound
      },
      {
        path: '/bot-speechs',
        name: 'BotSpeechs',
        component: BotSpeechs
      },
      {
        path: '/bot-bounded',
        name: 'BotBounded',
        component: BotBounded
      },
      {
        path: '/discount-coupon-list',
        name: 'DiscountCouponList',
        component: DiscountCouponList
      },
      {
        path: '/discount-coupon-manager',
        name: 'DiscountCouponManager',
        component: DiscountCouponManager
      },
      {
        path: '/push-campaign-list',
        name: 'PushCampaignList',
        component: PushCampaignList
      },
      {
        path: '/push-campaign-manager',
        name: 'PushCampaignManager',
        component: PushCampaignManager
      },
      {
        path: '/details-coupon-discount',
        name: 'DetailsCouponDiscount',
        component: DetailsCouponDiscount
      },
      {
        path: '/promotions',
        name: 'PromotionsList',
        component: PromotionsList
      },
      {
        path: '/promotion-info',
        name: 'PromotionInfo',
        component: PromotionInfo
      },
      {
        path: '/gateway-documents',
        name: 'GatewayDocument',
        component: GatewayDocument
      },
      {
        path: '/manager-user-permissions',
        name: 'ManagerUserPermissions',
        component: ManagerUserPermissions
      },
      {
        path: 'products-site',
        name: 'ProductsSite',
        component: ProductsSite
      },
      {
        path: '/device-history',
        name: 'DeviceHistory',
        component: DeviceHistory
      },
      {
        path: '/applications-list',
        name: 'ApplicationsList',
        component: ApplicationsList
      },
      {
        path: '/stock-backlogs',
        name: 'StockBacklogs',
        component: StockBacklogs
      },
      {
        path: '/events-history',
        name: 'EventsHistory',
        component: EventsHistory
      },
      {
        path: '/customers-exceptions-list',
        name: 'CustomersExceptionsList',
        component: CustomersExceptionsList
      },
      {
        path: '/stock-adjustment-documents',
        name: 'StockAdjustmentDocuments',
        component: StockAdjustmentDocuments
      },
      {
        path: '/stock-adjustment-details-items',
        name: 'DetailsItemsDocuments',
        component: DetailsItemsDocuments
      },
      {
        path: '/stock-adjustment-items',
        name: 'StockAdjustmentItemsDocuments',
        component: StockAdjustmentItemsDocuments
      },
      {
        path: '/gateway-recognition',
        name: 'CustomersRecognitionGateway',
        component: CustomersRecognitionGateway
      },
      {
        path: '/validate-app-code',
        name: 'ValidateAppCode',
        component: ValidateAppCode
      },
      {
        path: '/order-payments',
        name: 'OrderPayments',
        component: OrderPayments
      },
      {
        path: '/partners-pending-status',
        name: 'PartnersPendingStatus',
        component: PartnersPendingStatus
      }
    ]
  },

  {
    path: '/login',
    name: 'Login',
    component: Login
  },

  {
    path: '/reset-password',
    name: 'Redefinição de Senha',
    component: ResetPassword
  },

  {
    path: '/message',
    name: 'Message',
    component: Message
  },

  {
    path: '/tokens',
    name: 'Tokens',
    component: Tokens
  }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

router.beforeEach((to, from, next) => {
  if (to.meta.requiresAuth) {
    if (sessionStorage.getItem('logado') != undefined) {
      next()
    } else {
      router.push({
        name: 'Login'
      })
    }
  } else {
    next()
  }
})

export default router
