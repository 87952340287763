'use strict'

export function nonEmpty(object) {
  return object && object.length != 0
}

export function isEmpty(object) {
  return Object.entries(object).length == 0
}

export function convertArrayNumber(array) {
  return array.length > 0 ? array.join(',').split(',').map(Number) : ''
}

export function chunkArray(input, chunkSize) {
  let index = 0
  const arrayLength = input.length
  let tempArray = []

  for (index = 0; index < arrayLength; index += chunkSize) {
    const myChunk = input.slice(index, index + chunkSize)
    tempArray.push(myChunk)
  }

  return tempArray
}

export const EventType = Object.freeze({
  PRODUCT_NOT_ALLOWED_UNDER_EIGHTEEN: {
    type: 'PRODUCT_NOT_ALLOWED_UNDER_EIGHTEEN'
  },
  PRODUCT_MANUALLY_REMOVED_TO_THE_CART: {
    type: 'PRODUCT_MANUALLY_REMOVED_TO_THE_CART'
  },
  PRODUCT_MANUALLY_ADDED_TO_THE_CART: {
    type: 'PRODUCT_MANUALLY_ADDED_TO_THE_CART'
  },
  PRODUCT_REMOVED_FROM_CART: {
    type: 'PRODUCT_REMOVED_FROM_CART'
  },
  WIPE_CART: {
    type: 'WIPE_CART'
  },
  LOCKER_OPENED: {
    type: 'LOCKER_OPENED'
  },
  LOCKER_CLOSED: {
    type: 'LOCKER_CLOSED'
  },
  ORDER_CREATED: {
    type: 'ORDER_CREATED'
  }
})

export const UrlImage = Object.freeze({
  NO_IMAGE: {
    url: 'https://img.accesys.com.br/produtos/semimagem.png'
  }
})
